/* Add a black background color to the top navigation */
.topnav {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.7rem 9rem;
  position: sticky;
  top: 0;
  box-shadow: 0 0 8px #d9dedf;
  z-index: 10000000000000;
}
.topnav2 {
  height: 2.7rem !important;
  overflow: hidden;
  position: absolute;
}
.logo {
  width: 80%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.topnav ul {
  display: flex;
  justify-content: space-around;
  width: 100%;
  align-items: center;
}
.topnav button {
  background-color: #c8102e;
  padding: 0.7rem 2rem;
  border: none;
  font-size: 17px;
  color: white;
  cursor: pointer;
}
.topnav button:hover {
  background-color: #b9001f;
}
.topnav a {
  display: block;
  text-align: center;
  padding: 7px 16px;
  text-decoration: none;
  font-size: 17px;
  border-bottom: 2px solid white;
}
.topnav a:hover {
  border-bottom: 2px solid #c8102e;
}

.topnav .icon {
  display: none;
}

@media screen and (max-width: 1260px) {
  .logo {
    width: 20%;
  }
  .topnav {
    padding: 0.7rem 1rem;
  }
}

@media screen and (max-width: 600px) {
  .heroBanner-container h1 {
    font-size: 2rem !important;
  }
  .topnav .icon {
    display: block;
  }
  .logo {
    width: 100vw;
    padding: 0 1rem;
  }
  .topnav {
    flex-direction: column;
    /* height: 3rem;
        overflow: hidden; */
  }
  .navBody {
    transition: all 7s ease;
    /* background-color: rebeccapurple;
    position: relative !important;
    transform: translateY(-100%) !importan; */
    display: none !important;
    z-index: -1;
  }
  .click {
    display: block;
    transform: translateY(0%);
    transition: all 5s ease;
  }
  .topnav ul li {
    text-align: center;
  }
  .topnav ul {
    margin-top: 1rem;
    flex-direction: column;
    gap: 1rem;
  }
  .topnav button {
    width: 60vw;
  }
  .heroBanner-container {
    height: 50vh !important;
  }
}
.heroBanner-container {
  /* text-align: center; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 5rem;
  text-align: center;
  height: 70vh;
  width: 100%;
  padding: 3rem 1rem;
  background: url("./assets/banner1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: inherit;
  padding-bottom: 3rem;
}
.heroBanner-container p {
  color: #fff;
  margin-left: 14px;
  margin: 12px 0px 10px 12px;
}
.heroBanner-container span {
  display: block;
  letter-spacing: 1.7px;
}
/* .heroBanner-container p::before {
  content: "";
  position: absolute;
  margin-left: -10px;
  height: 40px;
  margin-top: 3px;
  width: 4px;
  background-color: #b9001f;
} */
.heroBanner-container h1 {
  /* line-height: 2rem; */
  font-size: 3.6rem;
  text-transform: uppercase;
  letter-spacing: 1.7px;
  color: #fff;
}
.heroBanner-container button {
  margin-top: 1.3rem;
  background-color: #c8102e;
  padding: 0.9rem 2rem;
  border: none;
  border-radius: 3px;
  font-size: 17px;
  color: white;
  cursor: pointer;
}

.login-container {
  width: 100%;
  background-color: #fff;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
  text-align: center;
  padding: 3rem 7rem;
}
.login-container > form {
  text-align: center;
  width: 100%;
  background-color: #ecf0f1;
}
.formBody {
  padding: 1rem;
}
.login-container > form > .formHead {
  background-color: #b9001f;
  color: #fff;
  padding: 0.5rem;
}
.login-container input[type="submit"] {
  background-color: #c8102e;
  color: #fff;
  border: none;
}
.login-container > form > .formHead h2 {
  font-weight: 400;
}
.login-container input {
  padding: 0.7rem;
  width: 100%;
  margin-top: 1rem;
}
@media screen and (max-width: 600px) {
  .login-container {
    gap: 1rem;
    grid-template-columns: 1fr;
    padding: 0.5rem;
  }
  .form-Left {
    text-align: center !important;
  }
}
.form-Left {
  text-align: left;
}
.form-Left h2 {
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.form-Left h2::before {
  content: "";
  position: absolute;
  margin-left: -10px;
  height: 30px;
  margin-top: 3px;
  width: 4px;
  background-color: #b9001f;
}
.form-Left span {
  color: #8a93a0;
}
.form-Left span span {
  color: #b9001f;
}

.team-slider {
  text-align: center;
  margin-top: 1rem;
  color: #000;
  padding: 2rem 0;
}
.team-card {
  padding: 0 8rem;
  padding-bottom: 3rem;
  display: flex;
  justify-content: space-around;
  text-align: left;
}
.team-slider h1:first-child {
  text-transform: uppercase;
  margin-bottom: 2rem;
}
.team-slider > h1::before {
  color: #c8102e;
  content: "";
  position: absolute;
  margin-left: -10px;
  height: 30px;
  margin-top: 3px;
  width: 4px;
  background-color: #b9001f;
}
.team-card img {
  width: 250px !important;
  height: 250px !important;
  border-radius: 50%;
}
.team-card-right-section {
  position: relative;
  /* display: grid;
  place-items: center; */
  width: 100%;
  padding: 3rem 4rem;
}

@media screen and (max-width: 600px) {
  .team-card img {
    width: 30% !important;
  }
  .team-slider h1::before {
    color: #c8102e;
    content: "";
    position: absolute;
    margin-left: -10px;
    height: 25px;
    margin-top: 3px;
    width: 4px;
    background-color: #b9001f;
  }
}
/* .slick-slide{
} */

.services {
  text-align: center;
  background-color: #ecf0f1;
  padding: 2rem;
}
.services article h2 {
  font-size: 1.2rem;
}
.services h1::before {
  color: #c8102e;
  content: "";
  position: absolute;
  margin-left: -10px;
  height: 25px;
  margin-top: 3px;
  width: 4px;
  background-color: #b9001f;
}
.service-main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 3rem;
  column-gap: 2rem;
  row-gap: 1rem;
}
.service-main article {
  margin-top: 2rem;
  box-shadow: 0 0 8px gainsboro;
  background-color: #fff;
  text-align: left;
  min-height: 300px;
  height: auto;
  position: relative;
  padding: 2rem;
  /* border: 2px solid;
  border-image: linear-gradient(
    to bottom, 
    #b9001f, 
    rgba(0, 0, 0, 0)
  ) 1 100%; */
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  align-items: center;
  border-radius: 32px 6px 32px 6px;
}
.service-main article img {
  margin-bottom: 0.5rem;
}
.service-main article img {
  position: relative;
  width: 40px;
  /* animation: float 4s infinite; */
}
.service-main article .servicesImg {
  border-radius: 12px 3px 12px 3px;
  position: absolute;
  top: -20px;
  left: 40%;
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  background-color: #c8102e;
  box-shadow: 0 0 15px #8a93a0cc;
}
.service-main article div h2 {
  margin-top: 30px;
}
@keyframes float {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-10px);
  }
  40% {
    transform: translateX(10px);
  }
  60% {
    transform: translateX(0);
  }
  80% {
    transform: translateX(10px);
  }
  100% {
    transform: translateY(0);
  }
}

.service-main article h2 {
  color: #b9001f;
  font-size: 17px;
  text-align: center;
}
.service-main article span {
  color: #8a93a0;
  display: block;
  text-align: justify;
  margin-top: 10px;
  /* letter-spacing: 1px; */
  line-height: 1.4rem;
  font-size: 12px;
}
/* .service-main article {
  display: flex;
  text-align: left;
  gap: 1rem;
}
.service-main article span {
  text-align: justify;
}
.Services-img img {
  padding-top: 0.3rem;
  width: 60px !important;
  height: 60px !important;
  background-color: #d9dedf;
  border-radius: 50%;
}
.Services-img img {
  width: 100%;
} */
.services > button {
  padding: 0.8rem 3rem;
  background: transparent;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 1rem;
  font-size: 1.2rem;
}
.services > button:hover {
  background-color: #c8102e;
  color: #fff;
  border: 2px solid #b9001f;
}
.services article button {
  padding: 0.4rem 2rem;
  /* background: transparent; */
  background-color: #c8102e;
  color: #fff;
  border: 2px solid #c8102e;
  border-radius: 7px;
  cursor: pointer;
  /* margin-top: 1rem; */
  font-size: 0.9rem;
}
.services article button:hover {
  background-color: #b9001f;
}
@media screen and (max-width: 600px) {
  .service-main {
    margin-top: 1rem;
    grid-template-columns: 1fr;
    column-gap: 0 !important;
    padding: 0 !important;
  }
}
@media (min-width: 601px) and (max-width: 1100px) {
  .service-main {
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;
    column-gap: 1rem !important;
    padding: 0 !important;
  }
  .login-container {
    padding: 3rem;
  }
  .footer {
    padding: 2rem !important;
  }
  .mission {
    flex-direction: column;
  }
  .mission img:nth-child(2) {
    height: 400px;
  }
}

/* @media screen and (min-width: 1100px){
  .service-main{
    margin-top: 1rem;
    grid-template-columns: 1fr 1fr;
    padding: 0;
  }
} */

.footer {
  background-color: #383838;
  color: #fff;
  padding: 2rem 10rem;
}
.footer h2 {
  margin-bottom: 2rem;
}
.footer li {
  list-style: none;
}
.footer h2::before {
  color: #c8102e;
  content: "";
  position: absolute;
  margin-left: -10px;
  height: 25px;
  margin-top: 3px;
  width: 4px;
  background-color: #b9001f;
}
.footer-main {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  text-align: center;
  line-height: 2rem;
}
@media screen and (max-width: 600px) {
  .footer {
    padding: 1rem !important;
    overflow: hidden;
  }
  .footer-main {
    grid-template-columns: 1fr;
  }
  .footer-service h2 {
    text-align: center !important;
    margin-top: 2rem;
  }
  .footer-contact h2 {
    text-align: center !important;
    margin-top: 2rem;
  }
  .footer h2 {
    margin-bottom: 0;
  }
  .footer .logo img {
    width: 100px;
    padding: 7px !important;
  }
}
.footer-main p {
  /* color: #c8102e; */
}
.footer-social {
  margin: auto;
  width: 6rem;
  display: flex;
  justify-content: space-around;
}
.footer-service {
  text-align: left;
}
.footer-contact {
  text-align: left;
}
.footer-contact p {
  margin-bottom: 1rem;
}
.footer .logo img {
  padding: 1rem;
  margin-top: 2rem;
  text-align: center !important;
  background-color: #ecf0f1;
}
.client {
  text-align: center;
  padding: 1rem;
  background-color: #ecf0f1;
  border-bottom: 2px solid #c8102e;
}
.client-imgs {
  display: flex;
  padding: 2rem;
  justify-content: center;
  align-items: center;
}
.client-imgs img {
  width: 13%;
  height: 10%;
  filter: grayscale(100%);
  cursor: pointer;
}
.client-imgs img:hover {
  filter: grayscale(0%);
}
.client h1::before {
  color: #c8102e;
  content: "";
  position: absolute;
  margin-left: -10px;
  height: 30px;
  margin-top: 3px;
  width: 4px;
  background-color: #b9001f;
}
.mission {
  display: flex;
}
.left-mission {
  background-color: black;
  color: #fff;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.left-mission div {
  display: flex;
  align-items: center;
}
.left-mission h2::before {
  color: #c8102e;
  content: "";
  position: absolute;
  margin-left: -10px;
  height: 25px;
  margin-top: 3px;
  width: 4px;
  background-color: #b9001f;
}
.left-mission > span {
  font-weight: bold;
  color: #c8102e;
  font-size: 1.6rem;
}
.left-mission > div > img {
  margin-right: 1rem;
}

@media screen and (max-width: 600px) {
  .mission {
    flex-direction: column;
  }
  .left-mission {
    padding: 2rem;
  }
  .left-mission span {
    font-size: 1.1rem;
  }
  .left-mission p {
    font-size: 0.8rem;
  }
  .left-mission h2 {
    margin-left: 0.8rem;
  }
  .mission img:nth-child(2) {
    height: 250px;
  }
}

/************************************************* messege ************************************/
.messege {
  text-align: center;
  padding: 1rem;
}
.messege > h1::before {
  color: #c8102e;
  content: "";
  position: absolute;
  margin-left: -10px;
  height: 30px;
  margin-top: 3px;
  width: 4px;
  background-color: #b9001f;
}
.messege-card {
  padding: 3rem 5rem;
  display: flex;
  justify-content: space-around;
}
.messege-card img {
  width: 350px;
  height: 350px;
  border-radius: 50%;
}
.messege-card-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 60%;
}
.messege-card-right h1{
  color: #012169;

}
.fa-icon:hover{
  margin: 0 0 0 1rem;
}
.fa-icon {
  margin: 1rem 0 0 1rem;
}
.messege-card-right {
  width: 100%;
  position: relative;
  text-align: left;
}
.messege-card-right h2{
  /* border-bottom: 4px solid #012169;
  width: 1rem; */
}
.messege-card-right-article {
  display: grid;
  place-items: center;
  height: 60%;
}
.messege-card-right h1 {
  font-size: 2.5rem;
}
.messege-card-right p {
  width: 80%;
  text-align: center;
  font-size: 1.25rem;
  /* background-color: red; */
}
.upper-quote {
  position: absolute;
  top: 6rem;
  left: 0;
}
.lower-quote {
  position: absolute;
  bottom: 4rem;
  right: 2rem;
}
@media screen and (max-width: 600px){
  .messege-card img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
  }
  .messege-card{
    flex-direction: column;
    padding: 1rem;
    /* background-color: red; */
  }
  .messege-card-left-article{
    width: 100%;
    /* background-color: #8a93a0; */
    /* background-color: green; */
  }
  .messege-card-left{
    width: 100%;
  }
  .messege-card-right{
    margin: 0;
    width: 100%;
    text-align: center;
    padding-bottom: 3rem;
  }
  .messege-card-right h1 {
    font-size: 1.2rem;
  }
  .messege-card-right p{
    width: 100%;
    font-size: 0.9rem;
  }
  .messege-card-right-article{
    /* background-color: red;
    width: 100vw; */
  }
  .upper-quote{
    top: 0;
    width: 2rem !important;
  }
  .lower-quote{
    bottom: -1rem;
    width: 2rem !important;
    right: -1rem;
  }
  .messege-card-right p{
    padding-top: 1rem;
    width: 100%;
  }
}
@media (min-width: 601px) and (max-width: 1100px) {
  .messege-card img{
    width: 200px;
    height: 200px;
  }
  .messege-card{
    padding: 1rem 1rem 2rem 1rem;
    flex-direction: column;
  }
  .messege-card-right p{
    font-size: 1rem;
  }
  .messege-card-left{
    width: 100%;
  }
  .messege-card-right{
    text-align: center;
  }
  .upper-quote{
    top: 1rem;
    width: 2.5rem !important;
  }
  .lower-quote{
    bottom: -1rem;
    width: 2.5rem !important;
    right: 0;
  }
  
}