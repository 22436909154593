* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
  /* scroll-behavior: inherit; */
  /* background-color: antiquewhite; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

li {
  list-style: none;
}

.chat {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
}
.footer-contact span img {
  margin-right: 7px;
}
a {
  color: black;
}

/* scroll trigger */

